/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js



exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/about`, `/tag/diagnosis`, `/tag/pet-guides`, `/tag/dog`, `/tag/dogs`, `/tag/cat`, `/tag/cats`, `/tag/aging-pet`, `/tag/faqs`, `/tag/geriatric-care`, `/tag/medical-info`, `/tag/pain-management`, `/tag/pet-services`, `/tag/surgical-info`, `/tag/treatment`  ]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.includes(pathname)) {
      return false
  }
    
window.scrollTo(0, 0);
}

// need to create another custom route that is from "/tag/..." to "/resources" 
// bonus, also if you can create a more elegant logic for combining any "resources/tag" as I have literally defined all current tags.